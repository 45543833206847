.ContactUs {
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    background-color: #7ecbff54;
    width: 80%;
    padding: 2rem 4rem;
    border-radius: 2rem 4rem 2rem 4rem / 2rem 2rem 2rem 2rem;
    border-bottom-right-radius: 10rem 10rem;
    border-bottom-left-radius: 10rem 10rem;

    h1 {
      margin: 0;
      font-family: "Candal";
      color: #ff6000ce;
      font-size: 3rem;
      margin-top: 10rem;
    }

    p {
      font-size: 1.3rem;
    }
  }

  .bottom {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .representatives {
      height: 30vh;
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      padding: 4rem 0rem 2rem 0rem;

      .rep {
        
        padding: 1rem 2rem;
        border-radius: 30px;
        box-shadow: 0px 5px 15px 0px #d8d8d8, inset 0 -15px 0 #7ecbff,
          0 45px 0 #0000;

        .up {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 1rem;

          .text {
            display: flex;
            align-items: center;
            gap: 1rem;
            img {
              width: 6rem;
              height: 6rem;
              border: 4px solid #7ecbff;
              border-radius: 50%;
            }
          }
        }
      }
    }
    h2 {
      font-size: 2.5rem;
      text-align: left;
      width: 55%;
      margin: 0;
    }

    #form-text {
      font-size: 1rem;
      text-align: left;
      width: 55%;
      font-weight: bold;
      margin: 0;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      padding: 4rem 2rem;
      width: 100%;
      justify-content: center;
      gap: 2rem;

      label {
        width: 24.5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 2px solid #cecece;
        border-radius: 35px;
        padding: 0.5rem 1.5rem;
        color: #cecece;
        font-weight: bold;
        position: relative;

        p {
          margin: 0;
          position: absolute;
          top: -20%;
          background-color: #fff;
          padding: 0rem 0.5rem;
          color: #000;
        }

        input {
          width: 90%;
          padding: 0.5rem 1rem;
          border: none;
          outline: none;
          font-family: "Raleway";
          font-size: 1rem;
        }
      }
      .button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          padding: 1rem 2rem;
          font-family: "Raleway";
          font-size: 1rem;
          background-color: #7ecbff;
          border: 2px solid #fff;
          border: none;
          width: 10%;
          border-radius: 30px;
          cursor: pointer;
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          align-self: center;

          &:hover {
            background-color: #fff;
            border: 2px solid #7ecbff;
          }
        }
      }

      #textarea {
        width: 55%;
        position: relative;

        p {
          position: absolute;
          top: unset;
          top: -6%;
        }

        textarea {
          width: 98%;
          height: 10rem;
          padding: 0.5rem 1rem;
          border: none;
          outline: none;
          font-family: "Raleway";
          font-size: 1rem;
        }
      }
    }
  }
}
