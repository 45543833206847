.Finalyze {
  padding: 4rem 0rem;

  .finalyze-banner {
    display: flex;
    padding: 8rem 2rem 2rem 2rem;
    background-color: #000;
    margin: 2rem;
    border-radius: 60rem 60rem 0px 0px;

    .left {
      flex: 1;
      position: relative;
      text-align: left;
      padding: 2rem;

      h4 {
        color: #000;
        font-size: 2rem;
      }

      .heading {
        position: absolute;
        top: 28.5rem;
        left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1 {
          font-size: 10rem;
          color: #52a87e;
          margin: 0;
        }

        span {
          display: flex;
          align-items: flex-end;

          h1 {
            margin: 0;
          }
        }
      }

      .place {
        height: 10rem;
        width: 100%;
        margin-bottom: 20rem;
      }

      p {
        color: #fff;
        font-weight: bold;
      }
    }

    .right {
      flex: 3;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 2rem;
      padding-right: 4rem;

      #mobile {
        height: 35rem;
        width: 23%;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 6px #52a87ea4;
        margin-bottom: 3rem;
      }

      #tablet {
        height: 45rem;
        width: 38%;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 6px #52a87ea4;
        margin-bottom: 3rem;
      }
    }
  }

  .finalyze-body {
    display: flex;
    padding: 2rem;

    .left {
      flex: 1;
      padding: 2rem;
      text-align: left;

      h1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #52a87e;
        padding: 0.5rem 0rem;

        span {
          color: #cecece;
        }
      }

      .actions {
        display: flex;
        gap: 1rem;

        button,
        a {
          flex: 1;
          padding: 1rem 2rem;
          border: none;
          font-family: "Raleway";
          cursor: pointer;
          text-align: center;
          font-size: 0.9rem;
          text-decoration: none;
        }

        #privacy {
          background-color: #52a87e;
          color: #fff;

          &:hover {
            background-color: #008000;
            color: #fff;
          }
        }

        #purge {
          color: #f00;
          background-color: #f1f1f1;
          font-weight: bolder;

          &:hover{
            background-color: #f00;
            color: #fff;
          }
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 30px;
      gap: 1rem;

      .card {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f1f1f1;
        border-radius: 30px;
        gap: 1rem;
        width: 50%;

        h4 {
          font-size: 1.5rem;
          margin: 0;
        }

        #rate {
          display: flex;
          align-items: center;
          gap: 1rem;

          span {
            color: green;
            font-size: 2rem;
          }
        }

        #size {
          display: flex;
          flex-direction: column;

          h1 {
            margin: 0;
            font-size: 4rem;
            color: #52a87e;
          }
        }

        #comp {
          display: flex;
          align-items: center;
          gap: 1rem;

          span {
            font-size: 3rem;
            color: #008000;
          }
        }

        button {
          flex: 1;
          padding: 1rem 2rem;
          border: none;
          font-family: "Raleway";
          cursor: pointer;
          text-align: center;
          font-size: 0.9rem;
          text-decoration: none;
          background-color: #cecece;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          border-radius: 10px;
          font-weight: bolder;

          &:hover{
            background-color: #008000;
            color: #fff;

            #play {
                color: #fff;
            }
          }

          #play {
            color: #008000;
            font-size: 2rem;
          }
        }

        
      }
    }
  }
}
