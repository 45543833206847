.Blog {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 80vh;

  h1 {
    background: linear-gradient(
      to right,
      #ff6000ce,
      #7ecbff,
      #ff6000,
      #7ecbff54
    );
    background-clip: border-box;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-family: "Candal";
    font-size: 6rem;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 1.3rem;
  }

  .notify {
    background-color: #fff;
    padding: 3rem 2rem;
    box-shadow: 0px 5px 15px 0px #d8d8d8, inset 0 -15px 0 #ff6200ab,
      0 45px 0 #0000;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
    width: 18%;

    label { 
        border: 1px solid #f1f1f1;
        display: flex;
        align-items: center;

        input {
            padding: 1rem 1.5rem;
            flex: 1;
            border: none;
            font-family: 'Raleway';
            outline: none;
        }


        button {
            padding: 1rem;
            font-family: 'Raleway';
            border: none;
            background-color: #ff6000ce;
            color: #fff;
            font-weight: bold;
        }
    }

    #check {
        border: none;

        span {
            font-size: 1rem;
        }
    }
  }
}
