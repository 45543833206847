.Purge {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 80vh;

  h1 {
    color: #f60;
    font-family: "Candal";
    font-size: 2rem;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 1.3rem;
    width: 25rem;
    text-align: left;

    strong {
      color: #f60;
    }
  }


  span {
    background-color: rgba(255, 0, 0, 0.233);
    padding: 1rem 2rem;
    width: 10%;
    color: #f00;
    border-radius: 10px;
  }

  .notify {
    background-color: #fff;
    padding: 3rem 2rem;
    box-shadow: 0px 5px 15px 0px #d8d8d8, inset 0 -15px 0 #ff6200ab,
      0 45px 0 #0000;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
    width: 30%;

    label {
      border: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        padding: 1rem 1.5rem;
        flex: 1;
        border: none;
        font-family: "Raleway";
        outline: none;
      }

      button {
        padding: 1rem;
        font-family: "Raleway";
        border: none;
        background-color: #f00;
        color: #fff;
        font-weight: bold;
      }
    }

    #check {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        flex: unset;
        padding: 0;
      }

      div {
        font-size: 1rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }
  }
}
