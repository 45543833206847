.DoubleCircle {
    position: absolute;
    top: 10%;
    left: -15%;

    .outer-circle {
        height: 30rem;
        width: 30rem;
        border: 10px solid #7ecbff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner-circle {
            height: 25rem;
            width: 25rem;
            border: 10px solid #ff6000ce;
            border-radius: 50%;
        }
    }
}