.Blob_widget {
    height: 50rem;
    width: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .blob_outer{
        height: 30rem;
        width: 40rem;
        background-color: #7ecbff32;
        position: absolute;
        border-radius: 2rem 4rem 2rem 4rem / 2rem 2rem 2rem 2rem;
        border-top-left-radius: 10rem 15rem;
        border-bottom-right-radius: 10rem 10rem;
        border-bottom-left-radius: 10rem 10rem;
        border-top-right-radius: 15rem 8rem;
    }

    .blob_inner{
        height: 30rem;
        width: 40rem;
        background-color: #7eccff32;
        position: absolute;
        top: 23%;
        left: 15%;
        border-radius: 2rem 4rem 2rem 4rem / 2rem 2rem 2rem 2rem;
        border-top-left-radius: 10rem 15rem;
        border-bottom-right-radius: 10rem 25rem;
        border-bottom-left-radius: 10rem 10rem;
        border-top-right-radius: 15rem 8rem;
    }
}