@import url("https://fonts.googleapis.com/css2?family=Candal");

.MobileApps {
  padding: 0rem 0rem 4rem 0rem;

  .mobile-intro {
    height: 70vh;
    background-color: #7ecbff54;
    border-bottom-right-radius: 1000px 550px;
    box-shadow: 2px 35px 3px 5px #0000;
    position: relative;
    text-align: left;

    .mobile-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin: 0;
        font-family: "Candal";
        color: #ff6000ce;
        font-size: 3rem;
        margin-top: 10rem;
      }

      p {
        width: 70%;
        color: #000;
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
      }
    }

    .triangle-one {
      border-bottom: 150px solid #7ecbff54;
      border-right: 100px solid transparent;
      border-left: 100px solid transparent;
      border-radius: 30px 50px;
      position: absolute;
      top: 50%;
      left: 10%;
    }

    .two {
      border-bottom: 50px solid #7ecbff54;
      border-right: 50px solid transparent;
      border-left: 50px solid transparent;
      top: 53%;
      left: 15%;
    }

    .three {
      border-bottom: 50px solid #7ecbff54;
      border-right: 50px solid transparent;
      border-left: 50px solid transparent;
      top: 20%;
      left: 82.5%;
    }

    .four {
      top: 1%;
      left: 80%;
    }
  }

  .budget {
    background-color: #fff;
    height: 20rem;
    width: 30rem;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0px 5px 15px 0px #d8d8d8, inset 0 -15px 0 #ff6200ab,
      0 45px 0 #0000;
    border-radius: 20px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      color: #ff6000ce;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5rem;

      input {
        width: 50%;
        padding: 0.5rem 1rem;
        font-family: "Raleway";
        border: 1px solid #f1f1f1;
        outline: none;
      }
    }

    button {
      background-color: #ff6000ce;
      border: none;
      padding: 0.5rem 1rem;
      width: 57.5%;
      align-self: flex-end;
      font-family: "Raleway";
      font-weight: bold;
      color: #fff;
    }
  }

  .portfolio {
    padding: 4rem;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h2 {
      margin: 0;
      color: #7ecbff;
      font-size: 2.5rem;
    }

    .portfolio-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0rem 4rem 0rem 4rem;
      gap: 4rem;

      .row-one-add {
        display: flex;
        gap: 2rem;

        .left {
          flex: 1;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem;

          img {
            height: 40rem;
            width: 18%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 6px #52a87ea4;
            margin-bottom: 3rem;
          }

          .screen-text {
            background-color: #272727;
            box-shadow: 2px 5px 5px 5px #f1f1f1;
            padding: 1rem 2rem;
            border-radius: 20px;
            text-align-last: left;
            width: 45%;

            h3 {
              color: #52a87e;
              font-weight: bolder;
            }

            span {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #d8d8d8;

              p {
                color: #52a87e;
              }
            }
          }
        }
      }

      .row-one {
        display: flex;
        gap: 2rem;

        .left {
          flex: 1;

          img {
            height: 40rem;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 6px #0d3c46a4;
            margin-bottom: 3rem;
          }

          .screen-text {
            background-color: #0d3c46;
            box-shadow: 2px 5px 5px 5px #f1f1f1;
            padding: 1rem 2rem;
            border-radius: 20px;
            text-align-last: left;

            h3 {
              color: #50a38c;
            }

            span {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;

              p  {
                color: #50a38c;
              }
            }
          }
        }

        .right {
          flex: 1;
          height: 40rem;
          position: relative;

          img {
            height: 40rem;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 6px #ffab91a4;
            margin-bottom: 3rem;
          }

          .screen-text {
            background-color: #ffab91;
            box-shadow: 2px 5px 5px 5px #f1f1f1;
            padding: 1rem 2rem;
            border-radius: 20px;
            text-align-last: left;

            h3 {
              color: #000;
            }

            span {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
