.WebDevelopment {
  .web-intro {
    height: 70vh;
    background-color: #7ecbff54;
    border-bottom-right-radius: 1000px 550px;
    box-shadow: 2px 35px 3px 5px #0000;
    position: relative;
    text-align: left;

    .web-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin: 0;
        font-family: "Candal";
        color: #ff6000ce;
        font-size: 3rem;
        margin-top: 10rem;
      }

      p {
        width: 70%;
        color: #000;
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
      }
    }

    .triangle-one {
      border-bottom: 150px solid #7ecbff54;
      border-right: 100px solid transparent;
      border-left: 100px solid transparent;
      border-radius: 30px 50px;
      position: absolute;
      top: 50%;
      left: 10%;
    }

    .two {
      border-bottom: 50px solid #7ecbff54;
      border-right: 50px solid transparent;
      border-left: 50px solid transparent;
      top: 53%;
      left: 15%;
    }

    .three {
      border-bottom: 50px solid #7ecbff54;
      border-right: 50px solid transparent;
      border-left: 50px solid transparent;
      top: 20%;
      left: 82.5%;
    }

    .four {
      top: 1%;
      left: 80%;
    }
  }

  .budget {
    background-color: #fff;
    height: 20rem;
    width: 30rem;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0px 5px 15px 0px #d8d8d8, inset 0 -15px 0 #ff6200ab,
      0 45px 0 #0000;
    border-radius: 20px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      color: #ff6000ce;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5rem;

      input {
        width: 50%;
        padding: 0.5rem 1rem;
        font-family: "Raleway";
        border: 1px solid #f1f1f1;
        outline: none;
      }
    }

    button {
      background-color: #ff6000ce;
      border: none;
      padding: 0.5rem 1rem;
      width: 57.5%;
      align-self: flex-end;
      font-family: "Raleway";
      font-weight: bold;
      color: #fff;
    }
  }

  .portfolio {
    margin-top: 15rem;

    h2 {
      color: #7ecbff;
      margin-bottom: 4rem;
      font-size: 2rem;
    }

    .row-one {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      margin-bottom: 4rem;

      .left {
        height: 40rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .top {
          display: flex;
          justify-content: center;
          gap: 0.5rem;
          width: 80%;

          #web {
            height: 40rem;
            width: 85%;
            box-shadow: 0px 0px 10px 6px #7ecbffa4;
            border-radius: 10px;
            aspect-ratio: 1;
          }

          #mobile {
            height: 40rem;
            width: 20%;
            box-shadow: 0px 0px 10px 6px #7ecbffa4;
            border-radius: 10px;
            aspect-ratio: 1;
          }
        }

        .bottom {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 4rem;

            h4 {
                font-size: 1.5rem;
                color: #000;
            }

            .decoration {
                position: absolute;
                height: 2rem;
                width: 15rem;
                background-color: #ff6000ce;
                top: 70%;
                border-bottom-right-radius: 250px;
            }
        }
      }

      .right {
        height: 40rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .top {
          display: flex;
          justify-content: center;
          gap: 0.5rem;
          width: 80%;

          #web {
            height: 40rem;
            width: 85%;
            box-shadow: 0px 0px 10px 6px #7ecbffa4;
            border-radius: 10px;
            aspect-ratio: 1;
          }

          #mobile {
            height: 40rem;
            width: 20%;
            box-shadow: 0px 0px 10px 6px #7ecbffa4;
            border-radius: 10px;
            aspect-ratio: 1;
          }
        }

        .bottom {
            position: relative;
            margin-top: 4rem;

            h4 {
                font-size: 1.5rem;
                color: #000;
            }

            .decoration {
                position: absolute;
                height: 2rem;
                width: 15rem;
                background-color: #ff6000ce;
                top: 70%;
                border-bottom-right-radius: 150px;
            }
        }
      }
    }
  }
}
