.About_bubble {
    .big_circle {
        height: 40rem;
        width: 40rem;
        border-radius: 50%;
        background-color: #7ecbff54;
        display: flex;
        align-items: center;
        justify-content: center;

        .small_circle {
            height: 35rem;
            width: 35rem;
            box-shadow: 0px 10px 10px 0px #7ecbff;
            background-color: #7ecbff54;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            #bubble {
                font-size: 10rem;
                color: #ff6000ce;
            }
        }
    }
}