.Policy-holder {
    padding: 4rem 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;

    .Policy {
        width: 70%;
    }
}