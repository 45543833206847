@import url("https://fonts.googleapis.com/css2?family=Candal");

.OurTeam {
  padding: 2rem 4rem;

  h1 {
    color: #ff6000ce;
    font-family: "Candal";
    font-size: 5rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .team-container {
    display: flex;
    flex-wrap: wrap;
    padding: 14rem 4rem;
    justify-content: center;
    gap: 20rem 0rem;

    .team-member {
      position: relative;
      width: 45%;
      height: 10rem;

      img {
        border: 1px solid #ff6000ce;
        background-color: #ff6000ce;
        height: 15rem;
        width: 15rem;
        border-top-right-radius: 150px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 20px;
        position: absolute;
        top: -110%;
        left: 50%;
        transform: translate(-50%);
        z-index: 20;
      }

      .team-text {
        box-shadow: 2px 5px 5px 5px #f1f1f1, inset 0 -15px 0 #ff6000ce,
          0 45px 0 #0000;
        padding: 1rem 2rem 3rem 2rem;
        border-radius: 20px;
        height: 12rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 40%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);

        h2 {
          margin: 0;
        }

        p {
          margin: 0;
          font-size: 1rem;
          font-weight: normal;
        }

        .socials {
          display: flex;
          align-items: center;
          gap: 2rem;
          padding-top: 1rem;

          #icon {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
